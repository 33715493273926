import React from 'react';
import { Link } from 'react-router-dom'
import './App.css';

async function getapi(url: string, custom: string) {
   let linkid = '-';
   let element = document.getElementById('copy') as HTMLInputElement;
   if (custom.length == 0) {
      linkid = await RequestNonCustom(url);
   }
   else {
      linkid = await RequestCustom(url, custom);
      if (custom != linkid) {
         alert('Sorry, this domain already has a shortened url.');
      }
   }
   if (element == null) return;
   element.value = "https://www.kortly.eu/" + linkid;
   onCopy();
   if (custom.length > 0 && custom != linkid) {
      let info = document.getElementById("copy");
      if (info == null) return;
      info.innerHTML = "Already exists!";
   }
}

function onCopy() {
   let element = document.getElementById('copy') as HTMLInputElement;
   element.select();
   document.execCommand('copy');
   element.blur();
}

async function RequestNonCustom(url: string) {
   let formData = new FormData();
   let linkid = '-';
   formData.append("link", url);
   await fetch('https://api.kortly.eu/shorten', {
      method: 'POST',
      body: formData
   })
      .then(response => response.text())
      .then(result => {
         linkid = result.replaceAll('"', '');
      })
      .catch(error => {
         console.log("Error!!", error);
         linkid = 'error';
      });
   return linkid;
}

async function RequestCustom(url: string, custom: string) {
   let formData = new FormData();
   let linkid = '-';
   formData.append("link", url);
   formData.append("custom", custom);
   await fetch('https://api.kortly.eu/shortencustom', {
      method: 'POST',
      body: formData
   })
      .then(response => response.text())
      .then(result => {
         linkid = result.replaceAll('"', '');
      })
      .catch(error => {
         console.log("Error!!", error);
         linkid = 'error';
      });
   return linkid;
}

function btn_pressed() {
   let url = (document.getElementById("url") as HTMLInputElement).value;
   if (url.length <= 5) {
      alert('Please provide a valid url');
      return;
   }
   let custom = (document.getElementById("custom") as HTMLInputElement).value;
   if (url.includes('http://') || url.includes('https://') || url.includes('www.')) {
      getapi(url, custom);
      return;
   } else {
      url = "https://" + url;
      getapi(url, custom);
      return;
   }
}

function copy_pressed() {

}

function MainLayout() {
   return (
      <div className='full'>
         <div className='topbanner'>
            <a className='bannertext'>Thank you for using <b className='bannertext'>kortly</b> link shortener. It's in development!</a>
         </div>
         <div>
            <br /><h1 className='header'>kortly</h1><br />
            <div className='space'>
               <input id='url' type='text' placeholder='URL' /> <br />
               <input id='custom' type='text' placeholder='Custom' /> <br />
               <div className='copyclass'>
                  <input id='copy' className='copytext'></input>
                  <button onClick={copy_pressed} className='copybtn'>Copy</button>
               </div><br />
               <button onClick={btn_pressed}>Create</button> <br />
               <br />
               <a className='error'>ERROR</a><br />
            </div>
         </div>
         <Link className='imprintlocation' to="/imprint">Imprint</Link>
      </div>
   );
}

export default MainLayout;
