import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MainLayout from './MainLayout';
import Redirect from './Redirect';
import Imprint from './imprint';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' Component={MainLayout}/>
        <Route path='/imprint' Component={Imprint}/>
        <Route path='/:id' Component={Redirect}/>        
      </Routes>
    </Router>
  );
}

export default App;
