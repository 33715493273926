import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { useParams } from 'react-router-dom';

function Redirect() {
   const [data, setData] = useState<string | null>(null);
   const [loading, setloaded] = useState(false);
   const { id } = useParams<{ id?: string }>();

   useEffect(() => {
      if (id) {
         fetchData(id);
      }
   }, []);

   async function fetchData(id: string) {
      try {
         const resp = await fetch("https://api.kortly.eu/get?link=" + id);
         if (!resp.ok) {
            throw new Error("HTTP error");
         }
         const respData = await resp.json();
         setloaded(true);
         setData(respData);
      } catch (error) {
         console.error('Fetch error: ', error);
         setloaded(true);
      }
   }
   if (loading && data == null) return <div>Loading...</div>
   else if (id === undefined) return <div>Error</div>
   else if (data) {
      window.location.href = data?.toString();
   } else {
      return (
         <div>
            URL not found!
         </div>
      )
   }
   return <div></div>
}

export default Redirect;
