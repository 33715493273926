import React from 'react';
import './App.css';

function Imprint() {
  return (
    <div>
      <h1>Imprint</h1>
      <a>Florian Schneider</a><br/>
      <a>c/o Block Services</a><br/>
      <a>Stuttgarter Straße 106</a><br/>
      <a>70736 Fellbach</a><br/>

      <h1>Data protection</h1>
      <a>There is no personal data collection on this website. <br/>
         It doesn't use any external trackers or analytic tools. <br/>
         We are not responsible for the content of the redirected website.

         </a>
    </div>
  );
}

export default Imprint;
